const colors = [
  {
    value: 0,
    label: 'Red',
  },
  {
    value: 1,
    label: 'Pink',
  },
  {
    value: 2,
    label: 'Purple',
  },
  {
    value: 3,
    label: 'Deep Purple',
  },
  {
    value: 4,
    label: 'Indigo',
  },
  {
    value: 5,
    label: 'Blue',
  },
  {
    value: 6,
    label: 'Light Blue',
  },
  {
    value: 7,
    label: 'Cyan',
  },
  {
    value: 8,
    label: 'Teal',
  },
  {
    value: 9,
    label: 'Green',
  },
  {
    value: 10,
    label: 'Light Green',
  },
  {
    value: 11,
    label: 'Lime',
  },
  {
    value: 12,
    label: 'Yellow',
  },
  {
    value: 13,
    label: 'Amber',
  },
  {
    value: 14,
    label: 'Orange',
  },
  {
    value: 15,
    label: 'Deep Orange',
  },
  {
    value: 16,
    label: 'Brown',
  },
  {
    value: 17,
    label: 'Grey',
  },
  {
    value: 18,
    label: 'Blue Grey',
  },
];

export default colors;
